import React, { useState } from "react"
import { Dropdown } from "react-bootstrap"

// Styles
import "../CustomDropdown/CustomDropdown.scss"

const CustomDropDown = ( dropDownData ) => {

    const { name, label, defaultOption = {}, options = [], onChangeCallback, variant = 'white' } = dropDownData;

    const [ selectedOption, setSelectedOption ] = useState( defaultOption );

    let getIconSource = ( variant ) => {
        if ( variant === 'black' ) {
            return 'arrow-bottom-icon-black.png';
        } else if ( variant === 'small-black' ) {
            return 'caret-down-icon-black.png';
        } else if ( variant === 'black-caret' ) {
            return 'caret-down-icon-black.png';
        }
        return 'arrow-bottom-icon-white.png';
    }

    const handleOnChange = ( option ) => {
        setSelectedOption( option );
        //onChangeCallback && onChangeCallback( name, option.value );
        onChangeCallback && onChangeCallback( option.value );
    }

    var hidden_value = selectedOption && selectedOption?.value;

    return (
        <div className={ `form-field custom-dropdown-wrapper ${ variant }` } >
            { label && (
                <label className="custom-label">
                    { label }
                </label>
            ) }
            <Dropdown>
                <Dropdown.Toggle variant="custom" id="dropdown-basic">
                    <span>{ selectedOption.display }</span>
                    <img
                        src={ require( `../../images/${ getIconSource( variant ) }` ).default }
                        alt="Arrow Down"
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        options.map( ( item, index ) => {
                            return (
                                <Dropdown.Item
                                    key={ index }
                                    onClick={ () => handleOnChange( item ) }
                                >
                                    { item.display }
                                </Dropdown.Item>
                            )
                        } )
                    }
                </Dropdown.Menu>
            </Dropdown>
            <input type={"hidden"} name={name} value={hidden_value} />
        </div>
    )
}

export default CustomDropDown
